// import React from "react";
import {
  ButtonTypeEnum,
  ButtonSizeEnum,
} from "app/components/shared/enums/global-enums.tsx";
import {AppRoutes} from "app/helpers/routes";
// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { buttonProps } from "app/components/shared/AppButton/AppButton";

export const navList: buttonProps[] = [
  {
    buttonLabel: "Contact",
    buttonType: ButtonTypeEnum.TEXT,
    buttonSize: ButtonSizeEnum.MEDIUM,
    redirectUrl: "/#contact",
  },
  {
    buttonLabel: "About Us",
    buttonType: ButtonTypeEnum.TEXT,
    buttonSize: ButtonSizeEnum.MEDIUM,
    redirectUrl: AppRoutes.aboutUsPath,
  },
  {
    buttonLabel: "Privacy Policy",
    buttonType: ButtonTypeEnum.TEXT,
    buttonSize: ButtonSizeEnum.MEDIUM,
    redirectUrl: AppRoutes.privacyPolicyPath,
  },
  {
    buttonLabel: "Log in",
    buttonType: ButtonTypeEnum.TEXT,
    buttonSize: ButtonSizeEnum.MEDIUM,
    redirectUrl: AppRoutes.signInPath,
    userNotLogged: true
  },

  // {
  //   buttonLabel: "Ask for a demo",
  //   buttonType: ButtonTypeEnum.TEXT,
  //   buttonSize: ButtonSizeEnum.MEDIUM,
  //   userNotLogged: true
  // },
  {
    buttonLabel: "Get Started",
    buttonType: ButtonTypeEnum.TEXT,
    buttonSize: ButtonSizeEnum.MEDIUM,
    redirectUrl: AppRoutes.signUpPath,
    userNotLogged: true
  }
];
