export interface TeamMember {
  name: string;
  teamMemberPhoto: string;
  position: string;
  soMe: SoMe[];
}

export interface SoMe {
  linkedIn: string;
  email: string;
}

export const teamMembers: TeamMember[] = [
  {
    name: "Shravya",
    teamMemberPhoto: "shravya.jpg",
    position: "Chief Executive Officer",
    soMe: [
      {
        linkedIn: "https://www.linkedin.com/in/shravyats/",
        email: "twitter",
      },
    ],
  },

  {
    name: "Lucienne",
    position: "Chief Business Officer",
    teamMemberPhoto: "lucienne.jpg",
    soMe: [
      {
        linkedIn: "https://www.linkedin.com/in/lucienne-blessing-3a8a004/",
        email: "twitter",
      },
    ],
  },
  {
    name: "Karam",
    position: "Chief Technical Officer",
    teamMemberPhoto: "karam.jpg",
    soMe: [
      {
        linkedIn: "https://www.linkedin.com/in/karam-ahkouk/",
        email: "twitter",
      },
    ],
  },
];
