import Api from 'app/helpers/api'
import {AppRoutes} from "app/helpers/routes";
import i18n from 'app/helpers/i18n'
export const usersSlice = ((set, get) => ({
  user: [],

  actions: {
    reloadUser: async () => {
      const api = new Api();
      api.reloadUser()
        .then((response) => {
          let user = response.data.user;
          if (!user || !user.access_token) get().actions.storeItem("user-access-token", null);
          if (user?.access_token) get().actions.storeItem("user-access-token", user.access_token);
          set((state) => ({ user: user }));
        })
      .catch((err) => console.log(err));
    },

    logout: async (navigate) => {
      const api = new Api();
      api.logout()
        .then((response) => {
          get().actions.storeItem("user-access-token", null);
          set((state) => ({ user: null }));
          navigate("/");
        })
      .catch((err) => console.log(err));
    },
    signIn: async (params, navigate) => {
      const api = new Api();
      api.signIn(params)
        .then((response) => {
          let accessToken = response.data.user.access_token;
          get().actions.storeItem("user-access-token", accessToken);
          set((state) => ({ user: response.data.user }));
          navigate(AppRoutes.dashboardPath);
        })
      .catch((err) => {
        get().actions.setSnackMessage({show: true, type:"ERROR",text: i18n.t(err.response.data[0].message)});
        
      });
    },

    signUp: async (params, navigate) => {
      const api = new Api();
      api.signUp(params)
        .then((response) => {
          // let accessToken = response.data.user.access_token;
          // get().actions.storeItem("user-access-token", accessToken);
          // set((state) => ({ user: response.data.user }));
          navigate("/");
          get().actions.setSnackMessage({show: true, type:"SUCCESS",text: i18n.t("Your request has been saved!")});
        })
      .catch((err) => {
        get().actions.setSnackMessage({show: true, type:"ERROR",text: i18n.t(err.response.data[0].message)});
        
      });
    },

  }

}))