import React from "react";
import {Link} from "react-router-dom";
import AppButton from "app/components/shared/AppButton/AppButton.tsx";
// import groupPhoto from "../../../assets/images/group-photo.svg";
import "./Hero.scss";
import { ButtonTypeEnum } from "app/components/shared/enums/global-enums.tsx";
import { RocketLaunch } from "@mui/icons-material";
import { Grid, Container } from "@mui/material";
import competencyEdge from "app/assets/media/competencyEdge.mp4";
import competencyEdgeWebm from "app/assets/media/competencyEdge.webm";
import {AppRoutes} from "app/helpers/routes.js"; 

export default function Hero() {
  return (
    <Container>
    <div className="v-space-5"></div>
    <Grid  container className=" hero-showcase ai-c" spacing={2}>
      <Grid item xs={12} md={6} className="hero-left-content">
        <div className="heading">
          Empowering an edge for a <span className="design">competent</span> tomorrow
        </div>
        <p>
          Nurturing Design Competencies
        </p>
        <div className=" hero-cta-container">
          <Link to={AppRoutes.signUpPath}>
            <AppButton
              sx={{marginBottom: '10px !important'}}
              buttonLabel={"Get Started"}
              buttonType={ButtonTypeEnum.PRIMARY}
              buttonStartIcon={<RocketLaunch />}
            />
          </Link>
        </div>
      </Grid>
      <Grid  item xs={12} md={6} className="d-f ai-c">
        <video  controls  playsInline    className="video-bg" >
          <source  src={competencyEdgeWebm}   type="video/webm" />
          <source  src={competencyEdge}   type="video/mp4" />
        </video>
        <div className="v-space-10"></div>
        <div className="v-space-10"></div>
      </Grid>
    </Grid>
    </Container>
  );
}
