import React, {useEffect, useRef} from "react";
import Footer from "app/components/shared/Footer/Footer";
import Navbar from "app/components/shared/Navbar/Navbar.tsx";
import { useLocation } from 'react-router-dom';
import Contact from "./Contact/Contact.jsx";
import Team from "./Team/Team.tsx";
import Features from "./Features/Features.tsx";
import Hero from "./Hero/Hero";

export default function Home() {



  const location = useLocation();
  const lastHash = useRef('');
  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        lastHash.current = '';
      }, 100);
    }
  }, [location]);

  return (
    <>
      <Navbar />
      <div className="container">
        <Hero />
        <Features />
        <Team />
        <Contact />
        <Footer />
      </div>
    </>
  );
}
