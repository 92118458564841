import React, {useEffect} from "react"
import {useNavigate} from "react-router-dom";
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import {Link} from "react-router-dom";
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import {
  ButtonTypeEnum, ButtonSizeEnum, ButtonColorEnum
} from "app/components/shared/enums/global-enums.tsx";
import AppButton from "app/components/shared/AppButton/AppButton.tsx";

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next'
import Footer from "app/components/shared/Footer/Footer";
import Navbar from "app/components/shared/Navbar/Navbar.tsx";
import {AppRoutes} from "app/helpers/routes";
import demo from "app/assets/images/demo.gif"
import useStore from 'app/store/store'
import './SignIn.scss'

const SignInSchema = Yup.object().shape({
  email: Yup.string().required('Required').email(),
  password: Yup.string().required('Password is required')
    .min(8, 'Password should be 8 chars minimum.')
});

export default function SignUp (){


  const Root = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    '& > :not(style) ~ :not(style)': {
      marginTop: theme.spacing(2),
    },
  }));


  const {t} = useTranslation();
  const navigate = useNavigate();

  const storeActions = useStore((state)=> state.actions);
  
  const accessToken = storeActions.retrieveItem("user-access-token");

  const initValues = {
            email: '',
            password: ''
          };


  useEffect(() =>{
    if (accessToken) {
      navigate(AppRoutes.dashboardPath);
    }
  }, [navigate, accessToken]);


  return (
    <>
      <Navbar/>
      <Container maxWidth="xl" >
        <div className="v-space-5"></div>
        <Grid container spacing={5}>
          <Grid item lg={7}>
            <Formik
              enableReinitialize={true} 
              initialValues={initValues}
              validationSchema={SignInSchema}
              onSubmit={(values)=>{
                  storeActions.signIn({user: values}, navigate);
                }
              }
            >
            {({ errors, touched }) => (
              <Form>
                <Grid container  sx={{paddingTop: 3, paddingBottom: 3, paddingLeft: 7, paddingRight: 7}}  className="signin-container">
                  <Grid item>
                    <Typography variant="span" className="black-color font-2" style={{fontSize: 32, fontWeight: 700}}>
                      {t("Welcome Back!")}
                    </Typography>
                  </Grid>
                  <div className="v-space-5"></div>


                  <Grid container item lg={12} className="d-f fd-c">
                    <Typography variant="span" className="second-color font-2" style={{fontSize: 16}}>
                      {t("Email")}
                    </Typography>
                    <div className="v-space-3"></div>
                    <Field   id="email" className="font-2 field w-100" name="email" placeholder={t("Email")} />
                    <div className="v-space-1"></div>
                    {errors.email && touched.email ? <div className="error-color">{errors.email}</div> : null}
                    <div className="v-space-3"></div>
                  </Grid>

                  <Grid  container  spacing={2}>
                    <Grid container item lg={12} className="d-f fd-c">
                      <Typography variant="span" className="second-color font-2" style={{fontSize: 16}}>
                        {t("Password")}
                      </Typography>
                      <div className="v-space-3"></div>
                      <Field  type="password" id="password" className="font-2 field w-100" name="password" placeholder={t("Password")} />
                      <div className="v-space-1"></div>
                      {errors.password && touched.password ? <div className="error-color">{errors.password}</div> : null}
                      <div className="v-space-3"></div>
                    </Grid>
                  </Grid>

                  <Grid item  lg={12} className="d-f">
                    <AppButton 
                      actionType="submit"
                      buttonSize={ButtonSizeEnum.LARGE}
                      sx={{fontWeight: 400, width: '100%'}}
                      buttonLabel={t('Log in')}
                      buttonType={ButtonTypeEnum.DEFAULT}
                    />
                  </Grid>
                  <div className="v-space-3"></div>
                  <Grid  container  item>
                    
                    <Typography variant="span" className="second-color font-2" style={{fontSize: 16}}>
                      {t("by_continuing_you_agree")}
                    </Typography>
                      
                  </Grid>
                  <div className="v-space-5"></div>
                  


                </Grid>
              </Form>
              )}
            </Formik>

            <div className="v-space-5"></div>

            <Grid  className="d-f ai-c fd-c" sx={{paddingLeft: 8, paddingRight:8}}  >
              <Root>
              <Divider sx={{ borderBottomWidth: '45px' }}>
              <Typography variant="span" className=" font-2" style={{fontSize: 22}}>
               {t('New to our community')}
              </Typography>
              </Divider>
              </Root>
              <div className="v-space-5"></div>
              <Link to={AppRoutes.signUpPath} style={{width: '100%'}}> 
                <AppButton 
                  buttonSize={ButtonSizeEnum.LARGE}
                  sx={{fontWeight: 400, width: '100%'}}
                  buttonLabel={t('Request a DEMO')}
                  buttonType={ButtonTypeEnum.DEFAULT}
                  buttonColor={ButtonColorEnum.SIXTH_FADED}
                />
              </Link>
            </Grid>
          </Grid>
          <Grid item lg={5} className="d-f ai-c">
            <img src={demo} className="w-100" alt=""  />
          
          </Grid>
          <div className="v-space-10"></div>
        </Grid>
      </Container>
      <Footer/>
    </>
    )
}