import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Container
} from "@mui/material";
import "./Team.scss";
import { TeamMember, teamMembers } from "./utils/TeamMembers.ts";
import { SoMe } from "./utils/TeamMembers";

export default function Team() {
  return (
    <Container >
      <div className="d-flex-column">
        <div className="v-space-10"></div>

        <div className="d-flex-column">
          <Typography variant="h4" className="title">
            Meet our team
          </Typography>
          <Typography variant="body1" className="mt-2 subtitle">
            We are three academics from different continents and with different backgrounds,who share a passion for enhancing education and a belief that education should be intentional in its design, transparent in its offering, tangible in its learning, and visible in its outcomes.
          </Typography>

          <Typography variant="body1" className="mt-2 subtitle">
            To make this belief a reality, and after many years of teaching and in-depth research, we founded Competency Edge, offering a unique, competency-based framework implemented in a powerful, user-friendly AI tool to support anyone involved in education
          </Typography>
        </div>
        <Grid alignItems="stretch" container className="jc-c ai-c my-5">
          {teamMembers.map((teamMember: TeamMember) => {

            return (
              <div key={teamMember.name} className="team-member">
                <Card>
                  <CardMedia
                    component="img"
                    image={require(`../../../assets/images/${teamMember.teamMemberPhoto}`)}
                    alt={teamMember.name}
                    className="member-image"
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="body1"
                      className="member-name"
                      sx={{ fontFamily: "poppins", fontWeight: 600 }}
                    >
                      {teamMember.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ fontFamily: "mulish" }}
                    >
                      {teamMember.position}
                    </Typography>
                  </CardContent>
                  {teamMember.soMe.map((socialMedia: SoMe) => {
                    return (
                      <div key={socialMedia.linkedIn}>
                        {
                          <CardActions className="some-container">
                            <a className="link" rel="noreferrer" target="_blank" href={socialMedia.linkedIn}>
                              LinkedIn
                            </a>
                          </CardActions>
                        }
                      </div>
                    );
                  })}
                </Card>
              </div>
            );
          })}
        </Grid>
      </div>
    </Container>
  );
}
